div.Container{
    text-align: center;
    margin: 0;
    padding: 0;

    div.CancleXBtn{
        text-align: left;
        
        span{
            font-size: 22px;
        }

        span:hover{
            cursor: pointer;
        }
    }
    
    div.TextContnet{
        .Title{
            span{
                font-weight: 550;
                font-size: 23px;
            }
        }

        .TowRowText{
            margin-top: 5px;
            display: flex;
            flex-direction: column;
            color: #808080;
            font-size: 16px;
        }
        ul {
            margin: 0;
            padding: 0;
        }

        li {
            list-style-type: none;
            padding: 0;
            margin: 0;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            width: 100%;
            cursor: pointer;
            /* label {
                display: inline-block;
                width: 70%;
                text-align: center;
                vertical-align: middle;
            } */
            
        }

        li :first-child {
            flex: 1 1 90px;
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            justify-content: center;
            margin: 0;
            padding: 0;
            white-space: nowrap;
            img {
                width: 100%;
                height: 25px;
                object-fit: contain;
            }
            input {
                margin: 0;
                padding: 0;
                box-sizing: border-box;
                flex: 0 0 20px;
            }
        }
        
        li :last-child{
            flex: 0 0 60px;
            white-space: nowrap;
        }


        div.Price{
            span:nth-child(1){
                font-weight: 650;
                font-size: 20px;
                margin: 5px;
            }
            span:nth-child(2){
                font-weight: 650;
                font-size: 30px;
            }
        }
    }
    
    div.ContinueBtn{
        display: flex;
        justify-content: center;
        margin-top: 10px;
        /* border: 1px solid #808080; */
        /* border-radius: 20px; */
        /* width: 55%; */

        span{
            border: 1px solid #808080;
            border-radius: 20px;
            width: 55%;
        }

        span:hover{
            cursor: pointer;
        }
    }
}