div.Container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 65vh;
    width: 35vw;
    min-width: 330px;
    position: relative;
    /* border: 3px solid blue; */

    div.CreditPayTitle{
        margin-top: 20px;
        width: max-conetnt;
        padding-left: 10px;
        padding-right: 10px;
        outline: none;
        color: #FFFF;
        border: none;
        border-radius: 3px;
    }

    /* form{
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    } */

    button{
        margin-top: 20px;
        width: 250px;
        height: 40px;
        outline: none;
        color: #FFFF;
        border: none;
        border-radius: 25px;
        /* margin: 0 7px 0 7px; */
        cursor: pointer;
        font-family: 'Heebo', sans-serif;
        font-size: 25px;
    }

    button:hover{
        opacity: .9;
    }

    div.InputFields form{
        /* width: 100vw; */
        /* border: 1px solid white; */
        display: flex;
        /* justify-content: center; */
        flex-direction: column;
        /* gap: 5px; */
        margin-top: 10px;
        align-items: center;
        height: 302px;


        div.CCNumber{
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #FFFFFF;
            width: 300px;
            height: 30px;
            border-radius:5px;
            font-size: 15px;
            font-weight: 300;
            color: #9b9b9b;
        }
        div.CCNumber:hover{
            cursor: default;
        }

        div.CVV {
            input{
                /* display: flex; */
                /* justify-content: flex-end;
                 */
                padding-left: 10px;
                direction: ltr;
                align-items: center;
                background-color: #FFFFFF;
                width: 120px;
                height: 30px;
                border-radius:5px;
                font-size: 15px;
                font-weight: 300;
                /* color: #9b9b9b; */
            }
        }
        /* div.CVV:hover{
            input{
                cursor: default;
            }
        } */


        input, select{
            display: inline-block;
            width: 300px;
            height: 25px;
            border:none;
            outline: none;
            /* border-width: 0px; */
            border-radius: 3px;
            resize: none;
            overflow: auto;
            font-family: arial;
        }

        input::placeholder{ /* Chrome, Firefox, Opera, Safari 10.1+ */
            padding-right: 0.3rem;
            color: #adabab;
            opacity: 1; /* Firefox */
        }
          
        input:-ms-input-placeholder{ /* Internet Explorer 10-11 */
            padding-right: 0.3rem;
            color: #adabab;
        }
          
        input::-ms-input-placeholder{ /* Microsoft Edge */
            padding-right: 0.3rem;
            color: #adabab;
        }


        div.ValidInfo{
            width: 300px;
            display: flex;
            /* gap: 6px; */
            justify-content: space-between;

            input:nth-child(3){
                direction: ltr;
                padding-left: 0.3rem;
            }
        }


        div.ValidInfo > * + *{
            margin-right: 6px;
        }
     

        div.BottomBtns{
            display: flex;
            flex-direction: column;
            /* gap: 15px; */
            /* margin-top: 70px; */
            /* margin-top: 70px; */
            position: absolute;
            bottom: 0;

            button{
                width: 350px;
                height: 35px;
                border-radius: 30px;
                outline: none;
                border:none;
                color: #FFFF;
                font-size: 18px;
            }
            
            button:nth-child(2){
                margin-top: 15px;
                background-color: unset;
                width: 350px;
                height: 35px;
                border-radius: 30px;
                outline: none;
                border: 2px solid rgb(255, 255, 255, 0.3);
                color: #FFFF;
                font-size: 18px;
                font-weight: 530;
            }
            button:nth-child(3){
                background-color: unset;
                width: 350px;
                height: 35px;
                border-radius: 30px;
                outline: none;
                border: 2px solid rgb(255, 255, 255, 0.3);
                color: #FFFF;
                font-size: 18px;
                font-weight: 530;
            }

            button:hover{
                cursor: pointer;
            }
        }

        div.BottomBtns > * + *{
            margin-top: 15px;
        }
    }

    div.InputFields form > * + *{
        margin-top: 5px;
    }
}

@media (max-width: 850px){
    div.Container{
        //width: 75vw;
        div.InputFields form{
            display: flex;
            /* justify-content: center; */
            flex-direction: column;
            /* gap: 5px; */
            margin-top: 10px;
            align-items: center;
            height: 302px;
            
   
            input, select{
                width: 220px;
            }

            div.ValidInfo{
                width: 220px;
            }    

            div.BottomBtns{
                display: flex;
                flex-direction: column;
                /* gap: 15px; */
                /* margin-top: 70px; */
                position: absolute;
                bottom: 0;
    
                button{
                    width: 250px;
                    height: 30px;
                }
                
                button:nth-child(2){
                    width: 250px;
                    height: 30px;
                }
                button:nth-child(3){
                    width: 250px;
                    height: 30px;
                }
    
            }


        }
    }
}