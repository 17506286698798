div.Container{
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 70vh;
    position: relative;

    .TitleText{
        /* border: 6px solid blue; */
        text-align: center;
        height: max-content;
        color: #FFFF;
        margin: 30px;
        font-weight: 400;
        font-size: 20px;
        font-family: 'Heebo', sans-serif;
    }

    .InputFields{
        /* border: 2px solid green; */
        height: 100%;

        form{
            /* border: 4px solid red; */
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            padding: 40px;
            margin-bottom: 20px;
            .Inputs::before {
                content: "";
                position: absolute;
                top: -20px;
                right: -20px;
                left: -20px;
                bottom: -20px;
                background: rgba(0, 0, 0, 0.5);
                z-index: -1;
            }
            .Inputs{
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;
            }
            .Inputs > * + *{
                margin-top: 6px;
            }
            overflow: auto;
            
            

            input{
                display: inline-block;
                width: 100%;
                height: 30px;
                border:none;
                outline: none;
                /* border-width: 0px; */
                border-radius: 3px;
                resize: none;
                overflow: auto;
                font-family: 'Heebo', sans-serif;
                padding: 2px 5px 2px 5px;
            }

            input::placeholder{ /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: #adabab;
                opacity: 1; /* Firefox */
            }
                
            input:-ms-input-placeholder{ /* Internet Explorer 10-11 */
                color: #adabab;
            }
                
            input::-ms-input-placeholder{ /* Microsoft Edge */
                color: #adabab;
            }

            div.CheckTerm{
                /* border: 1px solid blue; */
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                input{
                    width: 15px;
                }
                span{
                    width: 20rem;
                    font-size: 17px;
                    /* font-weight: 600; */
                    color: rgb(255,255,255)
                }

                .TextTermBtn{
                    font-weight: 900;
                    text-decoration: underline;
                    color: rgb(255,255,255,0.65);
                }
                
                .TextTermBtn:hover{
                    cursor: pointer;
                }
            }
            
            .Btns{
                display: flex;
                flex-direction: column;
                /* gap: 15px; */

                button{
                    width: 350px;
                    /* height: 50px; */
                    height: 35px;
                    border-radius: 30px;
                    outline: none;
                    border:none;
                    color: #FFFF;
                    /* font-size: 27px; */
                    font-size: 22px;
                    cursor: pointer;
                    font-family: 'Heebo', sans-serif;
                }
                button:hover{
                    opacity: .9;
                }

                #BackBtn{
                    background-color: unset;
                    border: 2px solid rgba(255, 255, 255, 0.3);
                }
            }
            .Btns > * + *{
                /* margin-top: 15px; */
                margin-top: 7px;
            }
        }
    }
    div.InputFields form > * + *{
        margin-top: 6px;
    }
}

@media (max-width: 850px){
    div.Container{
        .TitleText{
            /* padding-top: 15px; */
            padding-bottom: 15px;
            margin-bottom: 0;
            font-size: 90%;
        }
        div.InputFields form{
            input{
                width: 250px;
            }
            div.CheckTerm{
                width: 250px;
                span{
                }
                input{
                    width: 10%;
                }
            }
    
            .Btns{
                margin-top: 25px;
                button{
                    width: 250px;
                    height: 30px;
                    font-size: 19px;
                }
            }
            .Btns > * + *{
                margin-top: 10px;
            }
        }
    }
}