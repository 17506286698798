.ModalContainer {
    position: fixed;
    top: 0;
    left: 0;
    /* z-index: 9999; */
    width: 100%;
    height: 100%;
    display: flex;
    
    /* background-color: rgba(0, 0, 0, 0.5); */
    .CancelXBtn {
        position: absolute;
        top: 0;
        right: 0;
        width: 30px;
        height: 30px;
        background-color: #fff;
        border-radius: 50%;
        text-align: center;
        line-height: 30px;
        cursor: pointer;
    }
    .CourierInfo{
        background-color: var(--mainColor);
        padding-top: 1px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
}